export const environment = {
  production: true,
  version: "2.17.0",
  FLOCKFINDER_URL_PAYMENT: "https://web-stg.flockfinder.co.uk/plans/",
  FLOCKFINDER_URL_ACCOUNT: "https://web-stg.flockfinder.co.uk/account/",
  ASK_FLOCKFINDER: "https://ask-stg.flockfinder.co.uk",
  FLOCKFINDER_API_URL: "https://api-stg.flockfinder.co.uk",
  WEBSITE_ADDRESS: "https://app-stg.flockfinder.co.uk",
  mapsKey: "AIzaSyB9Xt0V7zWmVLGZ_XiFJlNo78WUBYfK_GA",
  placesAPI: "AIzaSyA-yoWaeOmeV5DyP_gRJibSl12VbFphMK",
  firebaseConfig: {
    apiKey: "AIzaSyD7570agDlO-6aNI1QxiafzwOnDcA58EbM",
    authDomain: "flockfinder-staging.firebaseapp.com",
    projectId: "flockfinder-staging",
    storageBucket: "flockfinder-staging.appspot.com",
    messagingSenderId: "1085919146476",
    appId: "1:1085919146476:web:c98e3df108f9ebbcca90c9",
    measurementId: "G-6WDPLGS6HV",
  },
  posthog: {
    apiKey: "phc_d9QnLf1a6igItHagKsbQRhE5mH8783jvwgxEZMHj1Za",
    host: "https://eu.i.posthog.com",
  },
};
